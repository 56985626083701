import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const MigraineSleepGraph = ({ data }) => {
  const svgRef = useRef();

  useEffect(() => {
    // Set dimensions
    const width = 1100;
    const height = 500;
    const margin = { top: 20, right: 150, bottom: 50, left: 50 };
    const scatterWidth = width - margin.left - margin.right;
    const scatterHeight = height - margin.top - margin.bottom;

    // Clear the SVG canvas
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    // Tooltip div
    const tooltip = d3
      .select('body')
      .append('div')
      .style('position', 'absolute')
      .style('background', '#fff')
      .style('border', '1px solid #ccc')
      .style('padding', '8px')
      .style('border-radius', '4px')
      .style('font-size', '12px')
      .style('box-shadow', '0px 4px 6px rgba(0, 0, 0, 0.1)')
      .style('opacity', 0);

    // Add the main group
    const g = svg
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Scales for scatter plot
    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => d.sleep_time) + 1])
      .range([0, scatterWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => d.migraine_count) + 1])
      .range([scatterHeight, 0]);

    // Axes
    const xAxis = d3.axisBottom(xScale).ticks(10);
    const yAxis = d3.axisLeft(yScale).ticks(6);

    // Append x-axis
    g.append('g')
      .attr('transform', `translate(0, ${scatterHeight})`)
      .call(xAxis)
      .append('text')
      .attr('fill', 'black')
      .attr('x', scatterWidth / 2)
      .attr('y', 40)
      .attr('text-anchor', 'middle')
      .text('Hours of Sleep');

    // Append y-axis
    g.append('g')
      .call(yAxis)
      .append('text')
      .attr('fill', 'black')
      .attr('transform', 'rotate(-90)')
      .attr('x', -scatterHeight / 2)
      .attr('y', -40)
      .attr('text-anchor', 'middle')
      .text('Migraine Frequency');

    // Add gridlines
    g.append('g')
      .attr('class', 'grid')
      .selectAll('line.horizontal')
      .data(yScale.ticks(6))
      .enter()
      .append('line')
      .attr('x1', 0)
      .attr('x2', scatterWidth)
      .attr('y1', d => yScale(d))
      .attr('y2', d => yScale(d))
      .attr('stroke', '#e0e0e0')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '4,4');

    g.append('g')
      .attr('class', 'grid')
      .selectAll('line.vertical')
      .data(xScale.ticks(10))
      .enter()
      .append('line')
      .attr('x1', d => xScale(d))
      .attr('x2', d => xScale(d))
      .attr('y1', 0)
      .attr('y2', scatterHeight)
      .attr('stroke', '#e0e0e0')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '4,4');

    // Add scatter plot points
    g.selectAll('.dot')
      .data(data)
      .enter()
      .append('circle')
      .attr('class', 'dot')
      .attr('cx', d => xScale(d.sleep_time))
      .attr('cy', d => yScale(d.migraine_count))
      .attr('r', 5)
      .style('fill', '#4682B4')
      .on('mouseover', (event, d) => {
        tooltip
          .html(
            `<strong>Date:</strong> ${d.date}<br/>
             <strong>User ID:</strong> ${d.user_id}<br/>
             <strong>Migraine Count:</strong> ${d.migraine_count}<br/>
             <strong>Sleep Time:</strong> ${d.sleep_time.toFixed(2)}`
          )
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY - 28}px`)
          .style('opacity', 1);
      })
      .on('mouseout', () => {
        tooltip.style('opacity', 0);
      });

    // Prepare data for side bar chart (include all migraine counts)
    const maxMigraineCount = d3.max(data, d => d.migraine_count);
    const migraineFrequency = d3.rollup(
      data,
      v => v.length,
      d => d.migraine_count
    );

    const migraineData = d3.range(0, maxMigraineCount + 1).map(count => ({
      migraine_count: count,
      frequency: migraineFrequency.get(count) || 0, // Default to 0 if count not found
    }));

    const barXScale = d3
      .scaleLinear()
      .domain([0, d3.max(migraineData, d => d.frequency)])
      .range([0, margin.right]);

    const barYScale = d3
      .scaleBand()
      .domain(migraineData.map(d => d.migraine_count))
      .range([scatterHeight, 0])
      .padding(0.1);

    // Append bar chart
    const barGroup = g.append('g').attr('transform', `translate(${scatterWidth + 20}, 0)`);

    barGroup
      .selectAll('rect')
      .data(migraineData)
      .enter()
      .append('rect')
      .attr('y', d => barYScale(d.migraine_count))
      .attr('height', barYScale.bandwidth())
      .attr('width', d => barXScale(d.frequency))
      .attr('fill', '#FF7F50');

    // Add bar chart labels
    barGroup
      .selectAll('text')
      .data(migraineData)
      .enter()
      .append('text')
      .attr('y', d => barYScale(d.migraine_count) + barYScale.bandwidth() / 2)
      .attr('x', d => barXScale(d.frequency) + 5)
      .attr('dy', '0.35em')
      .attr('fill', '#000')
      .text(d => d.frequency);

    // Cleanup tooltip on component unmount
    return () => tooltip.remove();
  }, [data]);

  return <svg ref={svgRef}></svg>;
};

export default MigraineSleepGraph;